.telegestaoMap{
  height: calc(100vh - 82px);
  position: relative;
}

.relatorio-alerta {
  width: 150px;
  background-color: white;
  position: absolute;
  z-index: 1500;
  right: 10px;
  top: 10px;
  border-radius: 5px;
  padding: 10px;
}


.relatorio-alerta .alert-row {
  cursor: pointer;
  padding: 5px;
}

.relatorio-alerta .alert-row img {
  height: auto; 
  width: auto; 
  max-width: 30px; 
  max-height: 30px;
}

.relatorio-alerta .alert-row:hover {
  background-color: rgba(0,0,0,.2);
}

.relatorio-alerta .alert-row .info {
  display: flex;
  justify-content: center;
  align-items: center;
}

.lum-0 {
  filter: brightness(5%);
}

.lum-1 {
  filter: brightness(100%);
}

.leaflet-container {
  height: 100vh;
}

#dashboard-modal .box-footer {
  display: flex;
  justify-content: end;
}

#dashboard-modal .contents > div {
  display: none;
}
#dashboard-modal .contents > div.active {
  display: flex;
}
#dashboard-modal .nav.nav-tabs li, 
#dashboard-modal .nav.nav-tabs li a {
  cursor: pointer;
}

#dashboard-modal > div {
  width: fit-content !important;
}

#dashboard-modal .dashboard-content .columns {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

#dashboard-modal .dashboard-content .equipament {
  width: 260px;
  height: 430px;
  justify-content: space-evenly;
  align-content: space-evenly;
}

#dashboard-modal .dashboard-content .metrics {
  width: 620px;
  height: 230px;
  justify-content: space-evenly;
  align-content: space-evenly;
}

#dashboard-modal .dashboard-content .programation {
  width: 620px;
  height: 200px;
  justify-content: space-evenly;
  align-content: space-evenly;
}

#dashboard-modal .dashboard-content .equipament, 
#dashboard-modal .dashboard-content .metrics, 
  #dashboard-modal .dashboard-content .programation {
  background-color: rgba(0,0,0,0.2);
}

.d-flex {
  display: flex;
}

.f-wrap {
  flex-wrap: wrap;
}

.f-dir-row {
  flex-direction: row;
}

.f-dir-col {
  flex-direction: column;
}

.jc-sa,
.f-jc-sa {
  justify-content: space-around;
}

.f-jc-c,
.f-jc-center {
  justify-content: center;
}

.w-fit-content {
  width: fit-content;
}

.f-1 {
  flex: 1;
}

.f-2 {
  flex: 2;
}

.f-3 {
  flex: 3;
}

.simple-data-container {
  background-color: white;
  border-radius: 5px;
  padding: 15px;
  width: max-content;
  height: 70px;
  align-content: flex-start;
}

.simple-data-container.image {
  height: fit-content;
}

.simple-data-container .info {
  font-weight: bold;
}

.simple-data-container .icon {
  margin-right: 5px;
}

.simple-data-container.image img {
  width: 100%;
}

