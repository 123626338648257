.containerCheckBoxLogin{
  font-family: system-ui, sans-serif;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.1;
}
.containerCheckBoxLogin input{
  accent-color: #19B3A8;
  margin-right: 10px;
  width: 1.15em;
  height: 1.15em;
}


.centerContainerLogin {
  background-color: #DEDEDE; 
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.passwordStyle {
  display: flex;
}
.passwordStyleInput {
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 20px;
  height: 46px;
  padding: 0 15px;
  font-size: 16px;
  width: 290px;
  border-radius: 20px 0px 0px 20px !important;
}

.passwordStyleView {
  border: 0;
  border-radius: 0px 20px 20px 0px;
  height: 46px;
  padding: 0 20px;
  font-size: 16px;
  font-weight: bold;
  background: #ff820e;
  color: #222222;
  cursor: pointer;
}

.passwordStyleView:hover {
  background: #ff820e;
}

.h1TextAlig{
  text-align: center;
  margin-bottom: 40px;
  color: #ff820e;
  font-size: 30px;
}
.styleLogin {
  width: 400px;
  background: #0B0B0B;
  border-radius: 20px;
  padding: 30px;
}

.styleLogin > p {
  font-size: 22px;
  line-height: 30px;
  margin-bottom: 30px;
}

.styleLogin form {
  display: flex;
  flex-direction: column;
}

.styleLogin form label {
  font-size: 16px;
  color: #F5F1E1;
  font-weight: bold;
  margin-bottom: 10px;
}

.styleLogin form label span {
  font-weight: normal;
  color: #999;
  font-size: 12px;
}

.styleLoginformInput {
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 20px;
  height: 46px;
  padding: 0 15px;
  font-size: 16px;
}

.styleLogin button.btn {
  border: 0;
  border-radius: 20px;
  width: 100%;
  height: 42px;
  padding: 0 20px;
  font-size: 16px;
  font-weight: bold;
  background: #ff820e;
  color: #222222;
  cursor: pointer;
}

.styleLogin button.btn:hover {
  background: #ff820e;
}
