.dashboardMapFirst {

    height: calc(100vh - 82px);
}

.loader-container-map {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.834);
    z-index: 1;
}

.spinner-map {
    width: 64px;
    height: 64px;
    border: 8px solid;
    border-color: #3C8DBC transparent #3C8DBC transparent;
    border-radius: 50%;
    animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}