 @import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

html, body, #root {
  min-height: 100%;
}

body {
  /* background: #1360AE; */
  /* background-image: linear-gradient(to right, rgba(255,0,0,0), #1360AE); */
  background: #F0ECDD;
  background-size: cover;
  -webkit-font-smoothing: antialiased !important;  
}

body, input, button {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 14px;
}

.container {
  position: absolute;
    left: 10px;
    bottom: 10px;
  /* margin: 80px auto 0; */
  max-width: 450px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.linha-vertical {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 2px;
  height: 450px;/*Altura da linha*/
  margin-top: -225px;
  border-left: 2px solid;/* Adiciona borda esquerda na div como ser fosse uma linha.*/
  border-color: #ff820e;
  box-sizing: border-box;
}

.logoJCA {
  position: absolute;
  left: 25%;
  top: 50%;
  width: 300px;
  border-radius: 4px;
  padding: 30px;
  transform: translate(-50%, -50%);
  /* background-color: #ebecec; */
}

.simbolo {
  position: fixed;
  left: 150px;
  top: -50px;
  width: 100px;
  background-color: rgb(230, 230, 230);
  border-radius: 50%;
}

.contentLogin {
  position: absolute;
  left: 75%;
  top: 50%;
  border: 1px solid #999;
  width: 400px;
  background: #0B0B0B;
  border-radius: 8px;
  padding: 30px;
  transform: translate(-50%, -50%);
}

.contentLogin > p {
  font-size: 22px;
  line-height: 30px;
  margin-bottom: 30px;
}

.contentLogin form {
  display: flex;
  flex-direction: column;
}

.contentLogin form label {
  font-size: 14px;
  color: #F5F1E1;
  font-weight: bold;
  margin-bottom: 8px;
}

.contentLogin form label span {
  font-weight: normal;
  color: #999;
  font-size: 12px;
}

.contentLogin form input {
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 2px;
  height: 46px;
  padding: 0 15px;
  font-size: 16px;
}

.contentLogin button.btn {
  border: 0;
  border-radius: 2px;
  width: 100%;
  height: 42px;
  padding: 0 20px;
  font-size: 16px;
  font-weight: bold;
  background: #ff820e;
  color: #222222;
  cursor: pointer;
  border-radius: 5px;
}

.contentLogin button.btn:hover {
  background: #ff820e;
}

.contentLogin > h2 {
  color: #ff820e;
  margin: 20px 0;
}

.override {
  display: block;
  margin: 0 auto;
  border-color: red;
}