#entrada-table-view thead th.whiteSpace {
    white-space: nowrap;
}

#entrada-table-view thead th.defaultWidth {
    width: 1px;
}

#entrada-table-view thead th.textAlignCenter {
    text-align: center;
}

#entrada-table-view thead th.textAlignRight {
    text-align: right;
}